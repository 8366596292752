/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '../src/app/shared/virtual-keyboard/virtual-keyboard.component.scss';

* {
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    // font-size: 15px;
    // line-height: 18px;
}

table {
    width: 100%;
    background-color: #fbfbfb;
    --background: #fbfbfb;
    font-size: 15px;
    line-height: 18px;

    .mat-cell,
    .mat-header-cell {
        font-size: 15px;
        line-height: 18px;
    }

    .mat-header-cell {
        font-weight: 600;
    }

    &.mat-table {
        box-shadow: none;
        background-color: #fbfbfb !important;
        --background: #fbfbfb;
    }
}

.link {
    cursor: pointer;
    font-weight: bold;
}

.cursor {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

ion-button[custom] {
    height: 44px;
    min-width: 45px;

    &::part(native) {
        padding-left: 16px;
        padding-right: 16px;
        background-color: #707070;
        text-transform: capitalize;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.2px;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0;
    }

    &[light] {
        &::part(native) {
            background-color: #F2F2F2;
            color: #262626;
        }
    }

    &:has(ion-icon[slot='icon-only']) {
        &::part(native) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

ion-textarea[custom] {
    --background: white;
    border-radius: 28px;
    border: 1px solid #707070;
    font-size: 17px;
    line-height: 20px;
    padding: 10px 24px 10px 27px !important;
    width: 100%;
}
div {
    &:has(> ion-textarea[custom]) {
        width: 100%;
        position: relative;
        margin-top: 16px;
        margin-bottom: 16px;

        ion-label {
            position: absolute;
            top: 1px;
            left: 20px;
            z-index: 11;
            background-color: white;
            padding: 0px 8px;
            font-size: 10px !important;
            line-height: 12px;
            opacity: 1 !important;
        }
    }
}


ion-input[custom] {
    --background: white;
    border-radius: 28px;
    font-family: "Raleway", sans-serif;
    border: 1px solid #707070;
    min-width: 140px;
    padding-left: 24px;
    padding-right: 16px;
    height: 55px;
    width: 100%;
    font-size: 17px;
    line-height: 20px;

    input {
        padding: 10px 24px 10px 27px !important;
        border-radius: 28px !important;
    }
}

div {
    &:has(> ion-input[custom]) {
        position: relative;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;

        ion-label {
            position: absolute;
            top: -5px;
            left: 20px;
            z-index: 11;
            background-color: white;
            padding: 0px 8px;
            font-size: 10px !important;
            line-height: 12px;
            opacity: 1 !important;
        }
    }
}

ion-item:has(ion-input[custom]) {
    padding-top: 12px;
    padding-bottom: 16px;

    div:has(> ion-input[custom]) {
        margin-bottom: 0px;
        margin-top: 4px;
    }

    ion-note {
        padding-left: 24px;
        padding-top: 0px;
    }
}

ion-select[custom] {
    background: white;
    border-radius: 28px;
    border: 1px solid #707070;
    min-width: 140px;
    padding-left: 26px;
    padding-right: 16px;
    height: 55px;
    width: 100%;
    font-size: 17px;
    line-height: 20px;
}

ion-select[custom]:hover {
    border: 1px solid #212121;
}

div:has(> ion-select[custom]) {
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 2px;
    margin-right: 2px;

    ion-label {
        position: absolute;
        top: -5px;
        left: 20px;
        z-index: 11;
        background-color: white;
        padding: 0px 8px;
        font-size: 10px !important;
        line-height: 12px;
    }
}

mat-tab-group[custom] {
    div[role='tab'] {
        border-radius: 15px 15px 0 0;
        background-color: #f7f7f7;

        &[aria-selected='true'] {
            opacity: 1;
            color: white;
            background-color: #707070;
        }
    }

    mat-ink-bar {
        visibility: hidden !important;
    }

    mat-tab-body {
        background-color: #f7f7f7;
        padding: 8px 12px;

        ion-list {
            background-color: #f7f7f7;
        }

        ion-item::part(native) {
            background-color: #f7f7f7 !important;
        }

        ion-select {
            background: transparent;
        }

        div:has(> ion-select[custom]) ion-label {
            background-color: #f7f7f7;
        }

        ion-input {
            background: transparent;

            input {
                background-color: transparent;
            }
        }

        div:has(> ion-input[custom]) ion-label {
            background-color: #f7f7f7;
        }
    }
}

ion-modal.custom {

    &::part(content) {
        border-radius: 15px;
        --max-width: 737px;
        --width: 100%;

        // not working :(
        // --height: auto;
    }

    ion-header {
        padding: 8px 40px 8px 40px;

        ion-toolbar {
            ion-buttons {
                b {
                    font-weight: bold;
                    font-size: 17px;
                    line-height: 20px;
                }
            }
        }
    }

    ion-toolbar.dialog-footer {
        padding: 22px 57px 22px 57px;
    }

    .content-wrapper {
        margin: 8px 57px;
        overflow-y: auto;
        height: 100%;

        &.list-type {
            margin: 8px 37px;
        }

        &::-webkit-scrollbar {
            width: 6px !important;
        }

        &::-webkit-scrollbar-track {
            background: white;
            //border-radius: 9px!important;
            //border: 1px solid #707070;
            //width: 14px
        }

        &::-webkit-scrollbar-thumb {
            background: #D8D8D8;
            //border-radius: 9px;
            //border: 1px solid white;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #707070;
        }
    }

    ion-content {
        &::part(scroll) {
            overflow-y: hidden;


        }
    }
}

ion-modal.extra-height {
    --height: 90%;
}

ion-modal.expedition-incidence-modal {
    --height: 918px;
    --max-height: 90vh;
}

ion-modal.product-detail-modal {
    --height: 780px;
    --max-height: 90vh;
}

ion-checkbox[custom] {
    --background-checked: var(--ion-color-primary, #3880ff);

    &::part(container) {
        border-radius: 50%;
    }
}

.contact-popover .popover-content{ width: 320px; }
/*ion-popover > #shadow-root > .popover-wrapper > .popover-content {
    top: calc(343.5px + var(--offset-y, 0px)) !important;
    left: calc(266px + var(--offset-x, 0px)) !important;
    transform-origin: left top !important;
    width: 60% !important;
}*/

ion-spinner.sc-ion-loading-md{
    color: #262626;
}

table[custom] {
    th {
        padding-right: 20px !important;
    }

    td {
        padding-right: 20px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

div.table-container {
    overflow-x: auto;
    max-width: 100%;

    &::-webkit-scrollbar {
        width: 6px !important;
    }

    &::-webkit-scrollbar-track {
        background: white;
    }

    &::-webkit-scrollbar-thumb {
        background: #D8D8D8;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #707070;
    }
}

:root {
    .popover-filter {
        --width: 279px;
        --offset-x: calc((100%/2 - 139.5px)*-1 + 50% - 279px/2);
    }
}

ion-content[custom] {
    .container-scroll-style {
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
            width: 10px !important;
        }

        &::-webkit-scrollbar-track {
            background-color: #262626;
            border-radius: 9px !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c4c4c4;
            border-radius: 9px !important;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #707070;
        }
    }

    ion-content {
        &::part(scroll) {
            overflow-y: hidden;
        }
    }

    .remove-scroll-small-menu {
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.mat-menu-panel {
    border-radius: 20px !important;
    top: 0;
    right: 5px;
    height: 96px;
    width: 190px;
}

.mat-menu-content:not(:empty) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


h1[custom] {
    font-family: 'Raleway', sans-serif;
    margin: 29px 0 16px 6px;
    font-weight: 500;
    font-size: 25px;
}

main-tool-bar {
    margin-left: -17px;
    margin-right: -28px;
}

@media screen and (max-width: 768px) {
    main-tool-bar {
        margin-left: 0;
        margin-right: 0;
    }
}

.container-page {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 50px 0 50px;
    padding-bottom: 32px;
}

.red-background {
    .alert-wrapper {
        background-color: #9B0000;
        max-height: none !important;
        max-width: 560px;
        margin-left: 40px;
        margin-right: 40px;
    }
    .alert-title, .alert-message, .alert-button {
        color: white;
    }
    .alert-message {
        max-height: none !important;
        padding-bottom: 28px;
    }

    .alert-head {
        height: 40px;
        padding-bottom: 46px;
        font-weight: 600;
    }

    .alert-button-group {
        padding-right: 8px;
        padding-top: 8px;
        flex-direction: row;
        button {
            font-size: 16px;
            margin-left: 0;
            padding-right: 4px;
            font-weight: 600;
        }
    }
}
