.content-keyboard {
    padding: 30px;
    ul {
        text-align: center;
    }

    input {
        width: 100%;
        height: 100px;
        padding: 20px;
        font-size: 20px;
        border: none;
        box-sizing: border-box;
    }

    .container-key-list {
        display: flex;

        .list-content-keyboard {
            height: 280px;
            margin-left: 20px;
            background-color: rgba(0, 0, 0, 0.1);
            padding: 5px;
            border-radius: 5px;

            .scroll-container {
                height: 100%;
                padding-top: 0;
                width: 200px;
                div {
                    --inner-border-width: 0;
                }
            }

            ion-list {
                height: 100%;
                padding-top: 0;
                width: 200px;
                overflow-x: auto;

                ion-item {
                    --inner-border-width: 0;
                }
            }
        }
    }

    .simple-keyboard {
        max-width: 850px;
    }

    .content-input {
        text-align: center;

        ion-searchbar {
            padding: 0 0 20px;
        }
    }
}

.virtual-keyboard-component {
    --top: 50%;
    --width: 70%;
    --left: 15%;
    --max-width: 1000px;
}

.simple-keyboard {
    font-family: "Raleway", sans-serif;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    touch-action: manipulation
}

.simple-keyboard .hg-row {
    display: flex
}

.simple-keyboard .hg-row:not(:last-child) {
    margin-bottom: 5px
}

.simple-keyboard .hg-row .hg-button-container,
.simple-keyboard .hg-row .hg-button:not(:last-child) {
    margin-right: 5px
}

.simple-keyboard .hg-row>div:last-child {
    margin-right: 0
}

.simple-keyboard .hg-row .hg-button-container {
    display: flex
}

.simple-keyboard .hg-button {
    display: inline-block;
    flex-grow: 1;
    cursor: pointer
}

.simple-keyboard .hg-button span {
    pointer-events: none
}

.simple-keyboard.hg-theme-default {
    background-color: rgba(0, 0, 0, .1);
    padding: 5px;
    border-radius: 5px
}

.simple-keyboard.hg-theme-default .hg-button {
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, .3);
    height: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.simple-keyboard.hg-theme-default .hg-button.hg-activeButton {
    background: #efefef
}

.simple-keyboard button.hg-button {
    border-width: 0;
    outline: 0;
    font-size: inherit
}

.simple-keyboard.hg-theme-default.hg-layout-numeric .hg-button {
    width: 33.3%;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center
}

.simple-keyboard.hg-theme-default .hg-button.hg-button-numpadadd,
.simple-keyboard.hg-theme-default .hg-button.hg-button-numpadenter {
    height: 85px
}

.simple-keyboard.hg-theme-default .hg-button.hg-button-numpad0 {
    width: 105px
}

.simple-keyboard.hg-theme-default .hg-button.hg-button-com {
    max-width: 85px
}

.simple-keyboard.hg-theme-default .hg-button.hg-standardBtn.hg-button-at {
    max-width: 45px
}

.simple-keyboard.hg-theme-default .hg-button.hg-selectedButton {
    background: rgba(5, 25, 70, .53);
    color: #fff
}

.simple-keyboard.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".com"] {
    max-width: 82px
}

.simple-keyboard.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
    max-width: 60px
}

.green{
    color: rgba(0, 170, 0, 0.75);
}
.orange{
    color: rgba(255, 204, 0, 0.75);
}
.red{
    color: rgba(225, 0, 0, 0.75);
}

@media screen and (max-width: 768px) {
    .content-keyboard {
        div.container-key-list {
            div.list-content-keyboard {
                margin-left: 0 !important;
                width: 100% !important;

                cdk-virtual-scroll-viewport {
                    width: 100% !important;
                }
            }
        }
    }

    .virtual-keyboard-component {
        --width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .content-keyboard {
        div.container-key-list {
            div.list-content-keyboard {
                margin-left: 0 !important;
                width: 100% !important;

                cdk-virtual-scroll-viewport {
                    width: 100% !important;
                }
            }
        }
    }

    .virtual-keyboard-component {
        --width: 90%;
    }
}
